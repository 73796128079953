import { DoctorAssessmentViewStatus } from 'app/types';

export const useCheckViewStatus = (
  viewStatus: DoctorAssessmentViewStatus | undefined,
  completedAt: string | null | undefined,
) => {
  // Conditions
  const isDraftMode = viewStatus === DoctorAssessmentViewStatus.Draft;
  const isView = viewStatus === DoctorAssessmentViewStatus.Completed || isDraftMode;
  const isNew = viewStatus === DoctorAssessmentViewStatus.New;
  //Doctor Assessment is completed when status returns Completed or completedAt exists
  const isCompleted = viewStatus === DoctorAssessmentViewStatus.Completed || !!completedAt;
  const isContinue = viewStatus === DoctorAssessmentViewStatus.Continue;
  const isReadOnly = viewStatus === DoctorAssessmentViewStatus.ReadOnly;

  return {
    isDraftMode,
    isView,
    isNew,
    isCompleted,
    isContinue,
    isReadOnly,
  };
};
