import uniq from 'lodash/uniq';
import classNames from 'classnames';
import { Field, Form, reduxForm } from 'redux-form';
import { IconChevronDown } from '@tabler/icons-react';
import { EventWithDataHandler } from 'redux-form/lib/Field';
import { ChangeEvent, ReactNode, useCallback, useMemo } from 'react';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  InputGroupText,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import {
  BillingItemSourceType,
  BillingItemType,
  ErrorType,
  InvoiceBillingItemOption,
  InvoiceDiscountType,
  InvoiceEligibility,
  InvoicePayBy,
  Option,
  RemoteDataParams,
} from 'app/types';
import {
  AsyncSelectComponent,
  FORMS_INVOICING_PROCESS_EDIT_ITEM,
  FORMS_INVOICING_PROCESS_PAYMENT_MODE,
  InputComponent,
  required,
  SelectComponent,
} from 'app/shared';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { PaymentModeField, PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import { useLimits } from 'app/features/invoicingProcess/process/items/itemForm/helpers/useLimits';
import { ItemField, ItemFormData } from 'app/features/invoicingProcess/process/items/itemForm/types';
import { TotalPrice } from 'app/features/invoicingProcess/process/items/itemForm/totalPrice/totalPrice';
import { fetchDropdownOptions } from 'app/redux/dropdownOptions/dropdownOptions.actions';
import { percentageNormalizer } from 'app/helpers/normalizers/percentageNormalizer';

export interface ItemFormProps {
  className?: string;
}

const FormComponent = ({
  change,
  className,
  error,
  form,
  handleSubmit,
}: ItemFormProps & InjectedFormProps<ItemFormData, ItemFormProps, ErrorType>) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const { isCreateCreditNoteMode, invoicePackageSnapshotId } = useInvoicingProcessState();
  const isEditMode = form === FORMS_INVOICING_PROCESS_EDIT_ITEM;
  const appointmentId = useAppSelector((state) => state.invoicingProcess.appointmentId);
  const doctorId: string | undefined = useAppSelector((state) => state.invoicingProcess.data?.doctor.value);
  const branchId: string | undefined = useAppSelector((state) => state.invoicingProcess.data?.clinicBranch.value);
  const items = useAppSelector((state) => state.invoicingProcess.items);
  const itemSourceTypes: Option<BillingItemSourceType>[] = useAppSelector(
    (state) => state.dictionaries.data['billing-item-source-types'] || [],
  );
  const itemSourceType: Option<BillingItemSourceType> | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[ItemField.itemSourceType],
  );
  const item: InvoiceBillingItemOption | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[ItemField.item],
  );
  const discountType: InvoiceDiscountType | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[ItemField.discountType],
  );
  const payBy: PaymentModeFormData['payBy'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.payBy],
  );
  const eligibility: PaymentModeFormData['eligibility'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.eligibility],
  );
  const insuranceId: PaymentModeFormData['insuranceCompany']['value'] | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.[PaymentModeField.insuranceCompany]?.value,
  );
  const isEligible = eligibility?.value === InvoiceEligibility.Eligible;
  const isReferralInOrPreviousApproval =
    eligibility?.value === InvoiceEligibility.ReferralIn || eligibility?.value === InvoiceEligibility.PreviousApproval;
  const isPayByInsurance = payBy?.value === InvoicePayBy.Insurance;
  const isItemSourceTypeDisabled =
    isCreateCreditNoteMode || !!invoicePackageSnapshotId || isReferralInOrPreviousApproval;
  const isItemDisabled =
    isCreateCreditNoteMode || !itemSourceType || !!invoicePackageSnapshotId || isReferralInOrPreviousApproval;
  const isPercentageDiscountType = discountType === InvoiceDiscountType.Percentage;
  const isDiscountDisabled = isPayByInsurance || !item || !!invoicePackageSnapshotId;
  const limits = useLimits(item, form);

  const performerBillingItemIdsToExclude = useMemo<string[]>(
    () => uniq(items.map((i) => i.billingItem.value)),
    [items],
  );

  const itemTypeOptions = useMemo<Option[]>(() => {
    if (payBy?.value === InvoicePayBy.Insurance && !appointmentId) {
      // Return only Medications option, for pay by insurance mode AND without appointment invoice
      return itemSourceTypes.filter((o) => o.value === BillingItemSourceType.Medication);
    }

    if (isEligible) {
      // Return only Billing Item option, for invoices with Eligible type
      return itemSourceTypes.filter((o) => o.value === BillingItemSourceType.BillingItem);
    }

    return itemSourceTypes;
  }, [appointmentId, isEligible, itemSourceTypes, payBy?.value]);

  const fetchItemOptions = useCallback(
    (params: RemoteDataParams) => {
      if (branchId && doctorId && itemSourceType) {
        return dispatch(
          fetchDropdownOptions(`/branches/${branchId}/performers/${doctorId}/performer-billing-items/dropdown`, {
            ...params,
            billingItemSourceType: itemSourceType.value,
            insuranceId: isPayByInsurance ? insuranceId : undefined,
            itemType: isEligible ? BillingItemType.Consultation : undefined,
            performerBillingItemIdsToExclude,
          }),
        );
      }
    },
    [
      branchId,
      dispatch,
      doctorId,
      isEligible,
      insuranceId,
      isPayByInsurance,
      itemSourceType,
      performerBillingItemIdsToExclude,
    ],
  );

  const formatOptionLabel = useCallback(
    (
      data: InvoiceBillingItemOption,
      formatOptionLabelMeta: FormatOptionLabelMeta<InvoiceBillingItemOption>,
    ): ReactNode => {
      if (formatOptionLabelMeta.context === 'value') {
        return data.label;
      }

      return (
        <>
          <span className="d-block fw-medium">{data.label}</span>
          <span className="d-block fw-light small">{data.details.internalCode}</span>
        </>
      );
    },
    [],
  );

  const discountAppendContent = useMemo<ReactNode>(() => {
    return (
      <InputGroupText>
        <UncontrolledDropdown disabled={isDiscountDisabled}>
          <DropdownToggle
            className={classNames('user-select-none', { 'cursor-pointer': !isDiscountDisabled })}
            tag="div"
          >
            <span className="me-1">
              {isPercentageDiscountType ? '%' : formatMessage({ id: 'CORE.TEXT.CURRENCY-SAR' })}
            </span>
            {!isDiscountDisabled && <IconChevronDown size={14} strokeWidth={2.5} />}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => change(ItemField.discountType, InvoiceDiscountType.Percentage)}>
              {formatMessage({ id: 'CORE.LABEL.PERCENTAGE' })}
            </DropdownItem>
            <DropdownItem onClick={() => change(ItemField.discountType, InvoiceDiscountType.Amount)}>
              {formatMessage({ id: 'CORE.LABEL.AMOUNT' })}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </InputGroupText>
    );
  }, [change, formatMessage, isDiscountDisabled, isPercentageDiscountType]);

  const onItemSourceTypeChange = useCallback<EventWithDataHandler<ChangeEvent<any>>>(() => {
    change(ItemField.item, null);
  }, [change]);

  return (
    <Form className={className} onSubmit={handleSubmit}>
      <Row xs={1} xl={3}>
        <Col>
          <Field
            disabled={isItemSourceTypeDisabled}
            name={ItemField.itemSourceType}
            component={SelectComponent}
            isRequired
            label={formatMessage({ id: 'BILLING-ITEMS.LABEL.ITEM-TYPE' })}
            onChange={onItemSourceTypeChange}
            options={itemTypeOptions}
            validate={[required]}
          />
        </Col>
        <Col>
          <Field
            disabled={isItemDisabled}
            name={ItemField.item}
            component={AsyncSelectComponent}
            cacheUniqs={[itemSourceType?.value, performerBillingItemIdsToExclude]}
            fetchOptions={fetchItemOptions}
            formatOptionLabel={formatOptionLabel}
            label={formatMessage({ id: 'CORE.LABEL.ITEM', defaultMessage: 'Item' })}
            isRequired
            showClinicyId={false}
            validate={[required]}
          />
        </Col>
        <Col>
          <Field
            name={ItemField.quantity}
            component={InputComponent}
            disabled={isEligible}
            label={formatMessage({ id: 'CORE.LABEL.QUANTITY', defaultMessage: 'Quantity' })}
            min={limits.quantity.min}
            max={limits.quantity.max}
            step={1}
            type="number"
            isRequired
            validate={[required]}
          />
        </Col>
        <Col>
          <Field
            name={ItemField.discountValue}
            appendContent={discountAppendContent}
            component={InputComponent}
            disabled={isDiscountDisabled}
            label={formatMessage({ id: 'CORE.LABEL.DISCOUNT', defaultMessage: 'Discount' })}
            min={limits.discountValue.min}
            max={limits.discountValue.max}
            step={isPercentageDiscountType ? 1 : 0.01}
            normalize={isPercentageDiscountType ? percentageNormalizer : undefined}
            type="number"
          />
        </Col>
        <Col>
          <TotalPrice form={form} />
        </Col>
        <Col>
          <Button block className="btn mt-2 mt-xl-4 mb-3" color="primary-gradient" size="md" type="submit">
            {isEditMode ? formatMessage({ id: 'CORE.BUTTON.SAVE' }) : formatMessage({ id: 'CORE.BUTTON.ADD' })}
          </Button>
        </Col>
      </Row>
      {error && typeof error === 'string' && <FormFeedback className="d-block mt-0 mb-3">{error}</FormFeedback>}
    </Form>
  );
};

export const ItemForm = reduxForm<ItemFormData, ItemFormProps, ErrorType>({
  enableReinitialize: true,
})(FormComponent);
