import { useMemo } from 'react';
import { PatientFormField, PatientFormData } from 'app/features/patient/patientForm/types';
import { PreferredLanguage } from 'app/shared';
import { useAppSelector } from 'app/helpers';
import { Option } from 'app/types';

export const useInitialValues = (initialPhoneNumber: string) => {
  const languageOptions: Option<PreferredLanguage>[] = useAppSelector(
    (state) => state.dictionaries.data['notification-languages'],
  );

  return useMemo(
    (): Partial<PatientFormData> => ({
      [PatientFormField.patientMobileNumbers]: [{ isDefault: true, phoneNumber: initialPhoneNumber }],
      [PatientFormField.preferredLanguage]: languageOptions.find((lang) => lang.value === PreferredLanguage.Arabic),
    }),
    [initialPhoneNumber, languageOptions],
  );
};
